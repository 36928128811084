<template>
  <section class="top-banner mb-1">
    <div class="container">
      <Agile :options="myOptions" class="owl-carousel owl-theme prices-of-flight theme-slider ltr p-0">
        <div class="slide item" v-for="(image, index) in topBanner" :key="index">
          <a :href="image.href">
            <div class="largestresort_box">
              <div class="thumb">
              <img :src="image.image" :alt="image.caption">
              </div>
              <h4>{{ image.caption }}</h4>
            </div>
          </a>
        </div>

        <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
        <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
      </Agile>
    </div>
  </section>
</template>

<script>
import TemplateTopBanner from './TemplateTopBanner';

export default {
  name: 'TopBannerTheme1',
  mixins: [TemplateTopBanner],
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  data() {
    return {
      myOptions: {
        autoplay: true,
        infinite: true,
        slidesToShow: 2,
        centerMode: true,
        autoplaySpeed: 3500,
        rtl: true,
        dots: false,
        speed: 5000,
        navButtons: false,
        timing: 'ease-in-out',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: false,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.agile__slide {
  padding: 0 10px;
}
.img-holder {
  position: relative;
  overflow: hidden;
}
.img-holder .caption {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background: white;
  text-align: center;
  width: fit-content;
  transition: 0.5s ease-out;
  cursor: pointer;
}
.img-holder:hover .caption {
  top: calc(50% - 20px);
}
.img-holder:hover img.linkon {
  filter: brightness(0.7);
}
.img-holder:hover .caption {
  box-shadow: #ddf1f9 0px 0px 50px;
}
.largestresort_box{ position: relative; overflow: hidden; border-radius: 12px; margin: auto; margin-top: 30px; width: 98%; }
.largestresort_box .thumb {
    display: flex;
    height: 460px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 12px;
}
.largestresort_box .thumb:after{ content: ""; position: absolute; left: 0; bottom: 0; width: 100%; background: rgb(246,87,74);
background: linear-gradient(180deg, rgba(246,87,74,0) 0%, rgba(3,43,61,0.9304096638655462) 91%); height: 100%; -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;  }
.largestresort_box .thumb img{ width: 100%; height: 100%; object-fit: cover; -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
.largestresort_box:hover .thumb img{
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.largestresort_box:hover .thumb:after{ height: 100%; background: rgb(0,0,0);
background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(3,43,61,0.011642156862745057) 38%, rgba(3,43,61,1) 67%);}
.largestresort_box h4 {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 28px 70px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    margin: 0;
}
@media(max-width: 1706px) {
  .largestresort_box h4 {

        padding: 28px 50px;
        font-size: 20px;
        line-height: 28px;

    }
}
@media(max-width: 1399px) {
  .largestresort_box .thumb {
       height: 400px;
    }
    .largestresort_box h4 {

        padding: 28px 45px;
        font-size: 20px;
        line-height: 28px;

    }
}
@media (max-width:991.98px){
  .largestresort_box .thumb {
        height: 300px;
    }
    .largestresort_box h4 {
        padding: 24px 20px;
        font-size: 18px;
        line-height: 24px;
    }
}
@media (max-width: 768px) {
  .owl-carousel.prices-of-flight .img-holder {
    height: auto;
  }
  .slide.item {
    padding: 0 2px;
  }
}
@media (max-width:479.98px){
    .largestresort_box .thumb {
        height: 260px;
    }
    .largestresort_box h4 {
        padding: 20px 10px;
        font-size: 16px;
        line-height: 22px;
    }
}
@media (max-width:410px){
    .largestresort_box .thumb {
        height: 240px;
    }
}
</style>
<style>

.top-banner .agile__nav-button {
  top: calc(50% - 20px);
}
</style>
